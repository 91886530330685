<template>
  <div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-3">
        Participants
      </p>
      <p class="text-mdh text-grey-dark-1 mx-2 max-w-3/5 mb-2">
        Select which company users you need to become participants in this
        assessment. Participants that are disabled indicate that those user have
        already started taken the assessment can no longer be removed.
      </p>
      <LicensesBanner />
      <ParticipantsTable />
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-3">
        Company Users
      </p>
      <div class="mx-2 mb-3">
        <CompanyUsersTable />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "../../../components/Checkbox";
import CompanyUsersTable from "../components/CompanyUsersTable";
import SetupTeamsTable from "../components/ParticipantsTeamsTable";
import LicensesBanner from "./LicensesBanner.vue";
import ParticipantsTable from "../components/ParticipantsTable";

export default {
  name: "UserSelect",
  components: {
    Checkbox,
    CompanyUsersTable,
    SetupTeamsTable,
    LicensesBanner,
    ParticipantsTable,
  },
};
</script>
