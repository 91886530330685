<template>
  <div>
    <div class="mb-2">
      <p class="text-mdh text-grey-dark-1 font-bold">Statuses:</p>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-grey-medium-2 rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-grey-medium-2">Not invited</span> = users that have
          not been invited to the platform
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-yellow-medium rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-yellow-medium">Invited</span> = users that have been
          sent emails to join the platform
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-green-2 rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-green-2">Active</span> = registered users
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-red rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-red">Deactivated</span> = user accounts that have been closed by admins
        </p>
      </div>
    </div>
    <div class="flex flex-grow justify-between items-center mb-2">
      <InputField
        placeholder="Name, job title or team"
        label="Filter by"
        :onChange="setKeyword"
        :propValue="keyword"
        leftIcon
        class="w-inputField"
        data-testid="filter-field"
      />
      <div class="flex justify-between items-center pt-2 space-x-2">
        <Button
          text="Download Template File (.CSV)"
          type="secondary"
          size="medium"
          class="w-250"
          @click.native="downloadTemplate"
          data-testid="download-button"
        />
        <AddNewButton class="w-175" :callback="getUsers" />
        <Button
          type="secondary"
          size="medium"
          text="Add to Participants"
          class="w-200"
          @click.native="addToParticipants"
          :iconLeft="plus"
          :disabled="selectedUsers === 0"
        />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} Users ({{ selectedUsers }}
      Selected)
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <div class="flex flex-row items-center focus:outline-none w-tableIndex">
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-row items-center focus:outline-none">
          <p class="text-sm-2 text-grey-light">STATUS</p>
        </div>
        <div class="flex flex-grow w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('firstName', 0)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobTitle', 1)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <div
            class="flex flex-row items-center focus:outline-none"
            data-testid="header-3"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
          </div>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('email', 3)"
            data-testid="header-4"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 364px; overflow-y: auto;"
          :data-key="'email'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
          data-testid="participants-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import plus from "@/assets/img/icons/add-blue.svg";
import { mapState, mapActions } from "vuex";
import Checkbox from "../../../../components/Checkbox/";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import InputField from "../../../../components/InputField/InputField.vue";
import Button from "../../../../components/Button/Button.vue";
import AddNewButton from "../AddNewButton.vue";

export default {
  name: "CompanyUsersTable",
  components: { Checkbox, VirtualList, InputField, Button, AddNewButton },
  data: () => ({
    keyword: "",
    plus,
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectAll: false,
    sorting: null,
  }),
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
      allUsers: (state) => state.assessmentSetup.allUsers,
      id: (state) => state.assessmentSetup.cloneId,
      licenses: (state) => state.assessmentSetup.licenses,
      availableInvites: (state) => state.invites.invitesInfo?.totalRemaining,
      allowUnlimitedInvites: (state) => state.invites.allowUnlimitedInvites,
      participants: (state) => state.assessmentSetup.participants,
      company: (state) => state.companies.currentCompany,
    }),
    selectedUsers() {
      return this.mappedList.filter((item) => item.isSelected).length;
    },
    isDraft() {
      return this.mode === "set-up";
    },
  },
  watch: {
    allUsers() {
      this.refreshMappedList();
    },
    keyword() {
      this.sorting = null;
      this.updateList();
    },
  },
  async mounted() {
    this.clearValidationUsers();
    await this.updateList();
  },
  methods: {
    ...mapActions({
      addParticipants: "assessmentSetup/addParticipants",
      getUsersFromOrganization: "assessmentSetup/getUsersFromOrganization",
      addValidationUsers: "assessmentSetup/addValidationUsers",
      clearValidationUsers: "assessmentSetup/clearValidationUsers",
      downloadTemplate: "utils/downloadTemplate",
      showError: "alerts/showError",
      getLicenses: "invites/getOrgInvitesInfo",
    }),
    getUsers() {
      return this.getUsersFromOrganization({
        id: this.id,
        pageNr: 1,
        pageSz: 100000,
        keyword: this.keyword,
        sorting: this.sorting,
      });
    },
    async addToParticipants() {
      let usersAlreadyIn = this.participants.map((item) => item.id);
      const selectedUsers = this.mappedList.filter((item) => item.isSelected);
      const leftInvites =
        this.availableInvites - selectedUsers.length - this.participants.length;
      if (!this.allowUnlimitedInvites && leftInvites < 0 && this.isDraft) {
        this.showError({
          messageHeader: "Add participants failed",
          message:
            "You don’t have enough invites to add this amount of participants. Contact ISM to purchase additional invites.",
        });
        return;
      }
      let ids = selectedUsers.map((item) => item.id).concat(usersAlreadyIn);
      this.addValidationUsers(selectedUsers);
      await this.addParticipants({ id: this.id, users: ids });
      this.keyword = "";
      await this.updateList();
      this.selectAll = false;
      await this.getLicenses(this.company.id);
    },
    async updateList() {
      await this.getUsers();
      this.refreshMappedList();
    },
    refreshMappedList() {
      this.mappedList = this.allUsers
        .map((item) => ({
          ...item,
          isSelected: false,
        }))
        .filter((item) => !item.isParticipatingInSurvey);
    },
    sort(key, index) {
      this.setRotation(index);
      switch (key) {
        case "firstName":
          this.sorting = { firstName: this.rotationMatrix[index] == 1 ? 1 : 2 };
          break;
        case "jobTitle":
          this.sorting = { jobTitle: this.rotationMatrix[index] == 1 ? 1 : 2 };
          break;
        case "email":
          this.sorting = { email: this.rotationMatrix[index] == 1 ? 1 : 2 };
          break;
        default:
          break;
      }
      this.updateList();
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    toggleItem(id) {
      this.mappedList.map((item) =>
        item.id === id ? (item.isSelected = !item.isSelected) : null
      );
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
  },
};
</script>
