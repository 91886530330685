<template>
  <div>
    <div v-if="participants.length !== 0" class="px-2">
      <div class="flex justify-between items-center mb-2">
        <p class="text-sm-2 text-grey-dark-2">
          Showing {{ participants.length }} Participants
        </p>
        <Button
          type="secondary"
          size="medium"
          text="Remove from Participants"
          :iconLeft="remove"
          @click.native="removeFromParticipants"
          :disabled="numberOfSelectedUsers === 0"
        />
      </div>
      <div>
        <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
          <button class="mr-2" @click="toggleAll">
            <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
          </button>
          <div
            class="flex flex-row items-center focus:outline-none w-tableIndex"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
          </div>
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light">STATUS</p>
          </div>
          <div class="flex flex-grow w-1/4">
            <button
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-1"
              @click="() => sort('name', 0)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/5">
            <button
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-2"
              @click="() => sort('jobTitle', 1)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow w-1/5">
            <div
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-3"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
            </div>
          </div>
          <div class="flex flex-grow w-1/5">
            <button
              class="flex flex-row items-center focus:outline-none"
              data-testid="header-4"
              @click="() => sort('email', 3)"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
        </div>
        <div>
          <VirtualList
            style="max-height: 364px; overflow-y: auto"
            :data-key="'email'"
            :data-sources="mappedList"
            :data-component="itemComponent"
            :extra-props="{ toggle: toggleItem }"
            data-testid="participants-table"
          />
        </div>
      </div>
    </div>
    <div v-else class="px-2 -mt-6 text-grey-dark-1 text-mdh">
      <p>No participants added yet.</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import remove from '@/assets/img/icons/remove.svg';
import Checkbox from '../../../../components/Checkbox/';
import ListItem from './ListItem.vue';
import VirtualList from 'vue-virtual-scroll-list';
import Button from '../../../../components/Button/Button.vue';

export default {
  name: 'ParticipantsTable',
  components: { Checkbox, VirtualList, Button },
  data: () => ({
    remove,
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectAll: false,
    lastSortingCriteria: undefined,
  }),
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
      id: (state) => state.assessmentSetup.cloneId,
      participants: (state) => state.assessmentSetup.participants,
      shouldEdit: state => state.assessmentSetup.shouldEdit,
      company: (state) => state.companies.currentCompany,
    }),
    numberOfSelectedUsers() {
      return this.mappedList.filter((item) => item.isSelected).length;
    },
  },
  mounted() {
    this.copyToLocal();
  },
  watch: {
    participants() {
      this.copyToLocal();
    },
  },
  methods: {
    ...mapActions({
      addParticipants: 'assessmentSetup/addParticipants',
      getUsersFromOrganization: 'assessmentSetup/getUsersFromOrganization',
      openModal: "modals/openModal",
      getLicenses: "invites/getOrgInvitesInfo",
    }),
    copyToLocal() {
      this.mappedList = this.participants.map((item) => ({
        ...item,
        teams: typeof item.teams === 'object' ? item.teams.map(el => el.name).join(", ") : item.teams,
        isSelected: false,
      })).sort((a, b) => {
        if(!this.lastSortingCriteria || this.lastSortingCriteria == 'name') {
            const x = a.firstName + ' ' + a.lastName;
            const y = b.firstName + ' ' + b.lastName;
            return this.rotationMatrix[0] * x.localeCompare(y);
        } 
        if(this.lastSortingCriteria == 'jobTitle') {
          return this.rotationMatrix[1] * a.jobTitle.localeCompare(b.jobTitle)
        }
        if(this.lastSortingCriteria == 'email') {
          return this.rotationMatrix[2] * a.email.localeCompare(b.email)
        }
        return 0;
      });
    },
    getUsers() {
      return this.getUsersFromOrganization({
        id: this.id,
        pageNr: 1,
        pageSz: 100000,
      });
    },
    async removeFn() {
      let toKeep = this.mappedList
        .filter((item) => !item.isSelected)
        .map((item) => item.id);
      await this.addParticipants({ id: this.id, users: toKeep });
      await this.getUsers();
      this.selectAll = false;
      await this.getLicenses(this.company.id);
    },
    removeFromParticipants() {
      if (this.shouldEdit) {
        this.openModal({ modal: 'removeParticipant', props: {
          remove: this.removeFn
        }});
      } else {
        this.removeFn();
      }
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => {
        if (!item.startedSurveyAt) item.isSelected = this.selectAll;
      });
    },
    toggleItem(id) {
      this.mappedList.map((item) =>
        item.id === id ? (item.isSelected = !item.isSelected) : null
      );
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      this.lastSortingCriteria = key;
      switch (key) {
        case 'name':
          this.mappedList.sort((a, b) => {
            let x = a.firstName + ' ' + a.lastName;
            let y = b.firstName + ' ' + b.lastName;
            return this.rotationMatrix[index] * x.localeCompare(y);
          });
          break;
        case 'jobTitle':
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.jobTitle.localeCompare(b.jobTitle)
          );
          break;
        case 'email':
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.email.localeCompare(b.email)
          );
        default:
          break;
      }
    },
  },
};
</script>
